// import useFetchData from "../services/useFetchData";

import { useState, useEffect } from "react";
import apiService from "../services/api.service";
// import authService from "../services/auth.service";
// import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// import authService from "../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

function AnkietaEdit() {
  // const { data } = useFetchData("http://127.0.0.1:8000/router/Questionnaire/");
  const [data, setData] = useState(null);
  // const [ankieta, setAnkieta] = useState(null);
  const [resp, setResp] = useState(null);
  const [redat, setRedat] = useState(null);
  const [reerror, setReerror] = useState(null);
  // const [id, setId] = useState(useParams());
  // const { id } = useParams();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [myMyUser, setmyMyUser] = useState(localStorage.getItem("user"));

  const [ankietaName, setAnkietaName] = useState(null);
  const [somePyt, setSomePyt] = useState(null);
  
  useEffect(() => {
    // const newAnkietObject = { name: ankietaName };
  }, []);

  const newAnkiet = (e) => {
    e.preventDefault();
    // alert('1. newAnkiet: ' + newAnkiet);
    // setMessage("");
    // setLoading(true);

    // form.current.validateAll();
    // apiService
    // .getListData("/router/QuestionnaireViewSet/")
    // .then((response) => {
    //   return response.data;
    // })
    // if (checkBtn.current.context._errors.length === 0) {
    if (true) {
      // alert('1aaaa. newAnkiet: ' + newAnkiet);
      const newAnkietObject = { name: ankietaName };
      apiService
      .addSingleElement("/router/QuestionnaireViewSet/", newAnkietObject)
      .then(
        (data) => {
          setData(data);

          alert(JSON.stringify(data));
          // setLoading(false);
          // navigate("/home");
          // window.location.reload();
        },
        (error) => {
          alert(JSON.stringify(error));
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          // setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
      // navigate("/home");
    }
  };

  return (
    <div>
      <h1>AnkietaEdit...</h1>
      <br></br>

      <Form onSubmit={newAnkiet} className=" ">
        <Form.Group className="mb-3" controlId="">
          <Form.Label>Pytanie</Form.Label>
          <Form.Control
            type="text"
            placeholder="wprowadz Pytanie"
            value={ankietaName}
            onChange={(e) => setAnkietaName(e.target.value)}
            validations={[required]}
          />
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="">
          <Form.Label>Nazwa Ankiety</Form.Label>
          <Form.Control
            type="text"
            placeholder="pytanie 1"
            value={ankietaName}
            onChange={(e) => setAnkietaName(e.target.value)}
            validations={[required]}
          />
        </Form.Group> */}
        {/* <Form.Group className="mb-3" controlId="">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            // autoComplete="on"
            validations={[required]}
          />
        </Form.Group> */}
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}

        
        <div className="float-end">
          <Button variant="warning" type="submit" disabled={loading}>
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}{" "}
            &nbsp;
            <span>ok</span>
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AnkietaEdit;
