import { useState, useEffect } from "react";
import axios from "axios";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// const API_URL = "http://localhost:8080/api/auth/";

// function App() {
//   // 2. Create our *dogImage* variable as well as the *setDogImage* function via useState
//   // We're setting the default value of dogImage to null, so that while we wait for the
//   // fetch to complete, we dont attempt to render the image
//   let [dogImage, setDogImage] = useState(null);

//   // 3. Create out useEffect function
//   useEffect(() => {
//     fetch("https://dog.ceo/api/breeds/image/random/3")
//       .then((response) => response.json())
//       // 4. Setting *dogImage* to the image url that we received from the response above
//       .then((data) => setDogImage(data.message));
//   }, []);

//   return (
//     <div className="App">
//       {/* 5. Returning an img element for each url, again with the value of our src set to the image url */}
//       {dogImage &&
//         dogImage.map((dog) => (
//           <img width={"200px"} height={"200px"} src={dog}></img>
//         ))}
//     </div>
//   );
// }

// export default App;
export default function L2() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [counter, setCounter] = useState(4);

  const [user, setUser] = useState(localStorage.getItem("user"));

  const API_URL = "http://127.0.0.1:8000/api/token/";

  // useEffect(() => {
  //   console.log("useEffect USER: " + user);
  // });
  // useEffect(() => {
  //   console.log("useEffect  [USER][]: " + user);
  useEffect(() => {
    fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(
        { 
          username: username,
          password: password,
          
        }),

      // body: {
      //    username,
      //    password,
      // },
      // body: JSON.stringify({
      //   username: username,
      //   password: password,
      // }),

      // {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ item })
      // })
    })
      .then((response) => {
        // alert("2 # - Response Comes " + response);
        // alert("2 # - Response Comes " + JSON.stringify(response));
        console.log("2 # - Response Comes");
        // response.json()
        if (!response.ok) {
          
          // alert("3  # - Response  if (!response.ok)");
          throw Error(response.statusText);
        }
        
        // alert("4 # - Response  if (!response.ok)");
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // alert("5 # - thenthenthen");
      })
      .catch((error) => {
        // alert("6 # - catchcatchcatch ");
        console.log(error);
      });
    // .then((data) => setDogImage(data.message));


  }, []);
  // }, [user]);

  const login = (username, password) => {
    // alert('sending: ' + username + ' ' + password);
    // console.log("1 # - login started");
    // alert("1 # - login started"); // zawsze jest
    return axios
      .post(API_URL, {
        username,
        password,
      })
      .then((response) => {
        alert("2 # - Response Comes");
        // console.log("2 # - Response Comes");
        // console.log("Our Response: " + JSON.stringify(response));
        // console.log(" # - xxxxxx");
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));

          // console.log("Our Response.DATA: " + JSON.stringify(response.data));
          alert("3 # - if (response.data) = true");
          // console.log(" # - xxxxxx");
          alert("good: " + JSON.stringify(response.data));
          // alert('xxxxxXXXXXXxxxxxxx');

          setUser(localStorage.getItem("user"));
        } else {
          alert("4 # - response.data = FALSE");
          // alert("not working");
          // console.log(" # - xxxxxx");
        }

        // console.log(" # - xxxxxx");
        alert("always Happend ");
        return response.data;
      });
  };

  const test = () => {
    // console.log("1 # - login started");
    // alert("xxxxxXXXXXXxxxxxxx");
    console.log(" test  test xxxxxXXXXXXxxxxxxx");
    return "done";
  };

  const logout2 = () => {
    console.log("logout2");
    // alert("logout2");
    localStorage.removeItem("user");
    setUser(localStorage.getItem("user"));
    // localStorage.setItem("user", JSON.stringify(response.data));
  };

  return (
    <div className="content w-50 mx-auto mt-3">
      {/* <h1>Welcome... Login</h1> */}
      <Form className=" ">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>L2</Form.Label>
          {/* <Form.Control type="email" placeholder="Enter email" value={name} /> */}
          <Form.Control
            type="text"
            placeholder="Enter email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            // onChange={(e) => setUsername(console.log(" # - xxxxxx" + e.target.value))}
          />
          {/* <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text> */}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          {/* <Form.Label>Password</Form.Label> */}
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="on"
          />
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
        <Button
          variant="primary"
          type="submit"
          onClick={() => setCounter(counter + 1)}
          // onClick={() => setCounter(counter += 1)}
        >
          Submit
        </Button>
        .
      </Form>
      <p>name: {username}</p>
      <p>password: {password}</p>
      <Button variant="danger" onClick={() => logout2()}>
        Log Out
      </Button>
      . . .
      {/* <Button variant="warning" onClick={() => test()}>
        TEST
      </Button> */}
      {user && <p>user: {user}</p>}
      {counter && <p>counter: {counter}</p>}
    </div>
  );
}
