import axios from "axios";




// const API_URL = "http://localhost:8080/api/auth/";
// const API_URL = "http://127.0.0.1:8000";
const API_URL = "http://anapi.ct8.pl";

const getApiUrl = () => {
  return API_URL;
};

const login = (username, password) => {
  return axios
    .post(API_URL + "/api/token/", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.access) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      // alert('aaaaaaaaa');
      return response.data;
    });
};
const logOut = () => {
  localStorage.removeItem("user");
  const windowRel = JSON.parse(localStorage.getItem("windowRel"));
  if (windowRel) {
    localStorage.removeItem("windowRel");
    window.location.reload();
  }
  //   window.location.reload();
};


const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const useAuth = () => {
  const user = getCurrentUser();
  if (user) {
    localStorage.setItem("windowRel", "true");
    return true;
  }
 return false;
};
const exportArray = {
  getApiUrl,
  useAuth,
  login,
  logOut,
  getCurrentUser,
};
export default exportArray;

// export default {
//   useAuth,
//   login,
//   logout,
//   getCurrentUser,
// };
