import React, { useState, useEffect } from "react";
// import  { useRef } from "react";
import { useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import authService from "../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  // const form = useRef();
  // const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  let navigate = useNavigate();

  const [myMyUser, setmyMyUser] = useState(localStorage.getItem("user"));

  useEffect(() => {
    console.log("useEffect  [USER][]: ");
  }, []);

  // const onChangeUsername = (e) => {
  //   const username = e.target.value;
  //   setUsername(username);
  // };

  // const onChangePassword = (e) => {
  //   const password = e.target.value;
  //   setPassword(password);
  // };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    // form.current.validateAll();

    // if (checkBtn.current.context._errors.length === 0) {
    if (true) {
      authService.login(username, password).then(
        (data) => {
          setLoading(false);
          navigate("/home");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
      // navigate("/home");
    }
  };

  return (
    // <div>
    //   <Button
    //     variant="danger"
    //     onClick={() => {
    //       navigate("/ankiety");
    //     }}
    //   >
    //     Log Out
    //   </Button>
    // </div>

    /////////////////////////////////////////////////////////////////////////////////

    <div className="content w-50 mx-auto mt-3">
      {/* <Button
        variant="danger"
        onClick={() => {
          navigate("/ankiety");
        }}
      >
        Log Out
      </Button> */}
      <Form onSubmit={handleLogin} className=" ">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Login</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            validations={[required]}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="on"
            validations={[required]}
          />
        </Form.Group>
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
        <Button variant="primary" type="submit" disabled={myMyUser}>
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}{" "}
          &nbsp;
          <span>Log In</span>
        </Button>
      </Form>

      <br></br>
      <br></br>
      <br></br>

      {myMyUser && (
        <div>
          <p>user: {myMyUser}</p>
          <Button
            variant="danger"
            onClick={() => {
              authService.logOut();
              setmyMyUser(localStorage.getItem("user"));
            }}
          >
            Log Out
          </Button>
        </div>
      )}
    </div>

    /////////////////////////////////////////////////////////////////////////////

    // <div className="col-md-12">
    //   <div className="card card-container">
    //     <img
    //       src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
    //       alt="profile-img"
    //       className="profile-img-card"
    //     />

    //     <Form onSubmit={handleLogin} ref={form}>
    //       <div className="form-group">
    //         <label htmlFor="username">Username</label>
    //         <Input
    //           type="text"
    //           className="form-control"
    //           name="username"
    //           value={username}
    //           onChange={onChangeUsername}
    //           validations={[required]}
    //         />
    //       </div>

    //       <div className="form-group">
    //         <label htmlFor="password">Password</label>
    //         <Input
    //           type="password"
    //           className="form-control"
    //           name="password"
    //           value={password}
    //           onChange={onChangePassword}
    //           validations={[required]}
    //         />
    //       </div>

    //       <div className="form-group">
    //         <button className="btn btn-primary btn-block" disabled={loading}>
    //           {loading && (
    //             <span className="spinner-border spinner-border-sm"></span>
    //           )}
    //           <span>Login</span>
    //         </button>
    //       </div>

    //       {message && (
    //         <div className="form-group">
    //           <div className="alert alert-danger" role="alert">
    //             {message}
    //           </div>
    //         </div>
    //       )}
    //       <CheckButton style={{ display: "none" }} ref={checkBtn} />
    //     </Form>
    //     <div className="form-group">
    //       <button
    //         className="btn btn-warning btn-block"
    //         onClick={() => authService.login(username, password)}
    //       >
    //         <span>Log IN IN IN </span>
    //       </button>
    //     </div>
    //     <div className="form-group">
    //       <button
    //         className="btn btn-danger btn-block"
    //         onClick={() => {
    //           authService.logOut();
    //           setLoading(false);
    //           setMessage('xxxx');
    //         }}
    //       >
    //         <span>Log OUT</span>
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Login;
