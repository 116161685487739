import { useState, useEffect } from "react";

function DoubleButtonHook() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    console.log(`You have clicked the first button ${count} times`);
  }, [count]);

  const [count2, setCount2] = useState(0);

  useEffect(() => {
    console.log(`You have clicked the second button ${count2} times`)
  }, [count2]);

  return (
    <div>
    <h1>DoubleButtonHook</h1>
    <p>State count =  {count} </p>
      <button onClick={() => setCount(count + 1)}>Click me</button>
    <p>State count 2 =  {count2} </p>
      <button onClick={() => setCount2(count2 + 1)}>Click me</button>
    </div>
  );
}

export default DoubleButtonHook;