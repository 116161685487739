import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

import DoubleButtonHook from "../after_delete/DoubleButtonHook";
import SingleState from "../after_delete/SingleState";
import Ankiety from "./Ankiety";
import AnkietaSolo from "./AnkietaSolo";
import AnkietaNowa from "./AnkietaNowa";
import AnkietaEdit from "./AnkietaEdit";
// import AnkietaLista from "./AnkietaLista";
import Users from "../after_delete/Users";
import Home from "./Home";
import LoggedOut from "./LoggedOut";

import Settings from "./Settings";
import Login from "./Login";
import Label from "../after_delete/Label";
import L2 from "../after_delete/L2";

import authService from "../services/auth.service";

// import EventBus from "../common/EventBus";
// localStorage.setItem("user", JSON.stringify(response.data));
// JSON.parse(localStorage.getItem("user"));
function MenuAndRouting() {
  const logOutVar = "/LoggedOut";
  
  const [isAuth, setIsAuth] = useState(false);
  const [shAll, setShAll] = useState(false);
  // const [shAll, setShAll] = useState(true);
  
  // let navigate = useNavigate();
  
  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      setIsAuth(true);
    } else {
      // setIsAuth(false);
    }

    setShAll(JSON.parse(localStorage.getItem("show")));
    
    // EventBus.on("logout", () => {
    //   logOut();
    // });

    // return () => {
    //   EventBus.remove("logout");
    // };

  }, []);

  useEffect(() => {
    localStorage.setItem("show",  JSON.stringify(shAll));
  }, [shAll]);


  // const setShowAll = () => {
  //   setShAll(!shAll);

  //   // const newShow = !shAll;
  //   // setShAll(newShow);
  //   // localStorage.setItem("show",  JSON.stringify(newShow));


  //   // console.log('shAll: ' + shAll);
  //   // localStorage.setItem("show", shAll);
  //   // localStorage.setItem("show", JSON.stringify(shAll));
  //   // console.log('shAll: ' + shAll);
  //   // console.log('JSON.parse.show: ' + JSON.parse(localStorage.getItem("show")));
  //   // console.log('localStorage.show: ' + localStorage.getItem("show"));
  //   // setShAll(!shAll);
  // };

  // const logOut = () => {
  //   navigate("/LoggedOut");
  //   // navigate("/login");
  //   setIsAuth(false);
  //   authService.logOut();
  //   window.location.reload();
  // };

  // const navToTarget = (target) => {
  //   navigate("/LoggedOut");
  // };

  // const logOut = () => {
  //   // authService.logOut();
  //   navigate("/LoggedOut");
  // };

  const ChAuth = () => {
    return authService.useAuth();
  };
  //   <Nav.Link className="ms-5"  href="/LoggedOut">
  //   <Nav.Link className="ms-5"  href={logOutVar} > 
  //   <Nav.Link className="ms-5" onClick={() => logOut()}>
  // sticky="top" vs fixed="top"
  return (
    <div>
      <nav>
        <Navbar bg="primary" variant="dark" className="mb-3 " fixed="top">
          <Container>
            <Navbar.Brand onClick={() => console.clear()}>Ankiety</Navbar.Brand>
            <Nav className="me-auto">
              {/* {true && <Nav.Link href="/double">double</Nav.Link>} */}
              {true && isAuth && <Nav.Link href="/home">Home</Nav.Link>}
              {true && true && <Nav.Link href="/ankiety">Ankiety</Nav.Link>}
              {/* {true && true && <Nav.Link href="/AnkietaLista">AnkietaLista</Nav.Link>} */}
              {shAll && isAuth  && <Nav.Link href="/label">Label</Nav.Link>}
              {shAll && isAuth && <Nav.Link href="/double">double</Nav.Link>}
            </Nav>

            <Nav className="ms-auto">
              {shAll && isAuth && <Nav.Link href="/single">s1</Nav.Link>}
              {shAll && isAuth && <Nav.Link href="/L2">L2</Nav.Link>}
              {shAll && isAuth && <Nav.Link href="/users">Users</Nav.Link>}
              {true && isAuth && <Nav.Link href="/Settings">Settings</Nav.Link>}

              {true && !isAuth && (
                <Nav.Link className="ms-5" href="/login">
                  Login
                </Nav.Link>
              )}
              {true && isAuth && (
              
                <Nav.Link className="ms-5"  href="/LoggedOut">
                  LogOut
                </Nav.Link>
              )}
              <Form className="ms-2">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={shAll}
                  onChange={() => {setShAll(!shAll)}}
                  // onChange={setIsAuth(!isAuth)}
                  // label="Check this switch"
                />
              </Form>
            </Nav>
          </Container>
        </Navbar>
      </nav>

      <Container className="Xborder mt-5">
      <div className="Xborder mt-5">
        <br></br>
      </div>
        <Routes>
          <Route path="/LoggedOut" element={<LoggedOut />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          <Route path="/Settings" element={ChAuth()?<Settings />:<Navigate to={logOutVar} />} />
          <Route path="/label" element={ChAuth()?<Label />:<Navigate to={logOutVar} />} />
          <Route path="/ankiety" element={true ? <Ankiety />:<Navigate to={logOutVar} />} />
          {/* <Route path="/AnkietaLista" element={true ? <AnkietaLista />:<Navigate to={logOutVar} />} /> */}
          <Route path="/AnkietaNowa" element={true ? <AnkietaNowa />:<Navigate to={logOutVar} />} />
          <Route path="/ankiety/:id" element={true ? <AnkietaSolo />:<Navigate to={logOutVar} />} />
          <Route path="/AnkietaEdit/:id" element={true ? <AnkietaEdit />:<Navigate to={logOutVar} />} />
          {/* <Route path="/ankiety/:id" element={true ? <AnkietaSolo />:<Navigate to={logOutVar} />} /> */}
          <Route path="/double" element={ChAuth() ? <DoubleButtonHook />:<Navigate to={logOutVar} />} />
          <Route path="/single" element={ChAuth() ? <SingleState />:<Navigate to={logOutVar} />} />
          <Route path="/users" element={ChAuth() ? <Users />:<Navigate to={logOutVar} />} />
          <Route path="/L2" element={ChAuth() ? <L2 />:<Navigate to={logOutVar} />} />
        </Routes>
        {/* <Routes>
          <Route path="/LoggedOut" element={<LoggedOut />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          <Route path="/Settings" element={ChAuth()?<Settings />:<Navigate to={logOutVar} />} />
          <Route path="/label" element={ChAuth()?<Label />:<Navigate to={logOutVar} />} />
          <Route path="/ankiety" element={ChAuth() ? <Ankiety />:<Navigate to={logOutVar} />} />
          <Route path="/double" element={ChAuth() ? <DoubleButtonHook />:<Navigate to={logOutVar} />} />
          <Route path="/single" element={ChAuth() ? <SingleState />:<Navigate to={logOutVar} />} />
          <Route path="/users" element={ChAuth() ? <Users />:<Navigate to={logOutVar} />} />
          <Route path="/L2" element={ChAuth() ? <L2 />:<Navigate to={logOutVar} />} />
        </Routes> */}
      </Container>
    </div>
  );
}

export default MenuAndRouting;
