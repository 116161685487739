import axios from "axios";
import authHeader from "./auth-header";
import authService from "./auth.service";

const API_URL = authService.getApiUrl(); 
// const API_URL = "http://127.0.0.1:8000";

const getApiUrl = () => {
  return authService.getApiUrl();
};

const logOut = () => {
  authService.logOut();
};

// const getPublicContent = () => {
//   return axios.get(API_URL + "all");
// };

const getListData = (extUrl) => {
  // alert('url: ' + API_URL + extUrl)
  // return axios.get('http://127.0.0.1:8000/router/QuestionnaireViewSet/', { headers: authHeader() });
  // return axios.get('http://127.0.0.1:8000/router/QuestionnaireViewSet/', { headers: authHeader() });
  return axios.get(API_URL + extUrl, { headers: authHeader() });
};

const getSingleElement = (extUrl, id) => {
  // alert('url: ' + API_URL + extUrl)
    return axios.post(API_URL + extUrl + id + "/", { headers: authHeader() });
};
const addSingleElement = (extUrl, object) => {
  // alert('2 url: ' + API_URL + extUrl + "");
  return axios.post(API_URL + extUrl + "", object, { headers: authHeader() });
};
const deleteSingleElement = (extUrl) => {
 if( window.confirm('Really delete ? :: ' + extUrl)){
   // alert('2 url: ' + API_URL + extUrl + "");
    return axios.delete(API_URL + extUrl, { headers: authHeader() });
  }
};

// const getUserBoard = () => {
//   return axios.get(API_URL + "user", { headers: authHeader() });
// };

// const getModeratorBoard = () => {
//   return axios.get(API_URL + "mod", { headers: authHeader() });
// };

// const getAdminBoard = () => {
//   return axios.get(API_URL + "admin", { headers: authHeader() });
// };

const exportArray = {
  getApiUrl,

  getListData,
  addSingleElement,
  getSingleElement,
  deleteSingleElement,

  logOut,

  // getPublicContent,
  // getUserBoard,
  // getModeratorBoard,
  // getAdminBoard,
};
export default exportArray;

// ! ####  BACKEND_ACCESS_FROM_ANGULAR  ##############
//#region  BACKEND_ACCESS_FROM_ANGULAR  ##############

// getAnyObjects(urExt: string): Observable<any[]> {
//   return this.http.get<any[]>(this.url + '/' + urExt, this.httpTokenOptions).pipe(
//     tap(_ => this.log('fetched ' + urExt))
//     // , catchError(this.handleError<any[]>('get_' + urExt, []))
//   );
// }

// getAnyObject(id: number, urExt: string): Observable<any> {
//   const url = this.url + '/' + urExt + id + '/';
//   // alert(this.httpTokenOptions);
//   return this.http.get<any>(url, this.httpTokenOptions).pipe(
//     tap(_ => this.log('fetched Object id=' + urExt + id))
//     // , catchError(this.handleError<any>(`getObject id=${id}`))
//   );
// }

// deleteAnyObject(obj: any | number, urExt: string): Observable<any> {
//   const id = typeof obj === 'number' ? obj : obj.id;
//   // const url = `${this.url + urExt + 'project/'}${id}/`;
//   const url = this.url + '/' + urExt + id + '/';
//   return this.http.delete<any>(url, this.httpTokenOptions).pipe(
//     tap(_ => this.log(`deleted Object (` + urExt + `id=${id}`))
//     // , catchError(this.handleError<any>('deleteObject'))
//   );
// }

// addAnyObject(obj: any, urExt: string): Observable<any> {
//   return this.http.post<any>(this.url + '/' + urExt, obj, this.httpTokenOptions).pipe(
//     tap((newObj: any) => this.log(`added newObj (` + urExt + `) w/ id=${newObj.id}`))
//     // , catchError(this.handleError<any>(`added newObj (` + urExt + `)`))
//   );
// }

// fileUpload(formData, extUrl) {
//   return this.http.post<any>(this.url + '/' + extUrl, formData);
//   return this.http.post<any>(this.url + '/' + extUrl, formData, this.httpTokenOptions);
// }

// updateAnyObject(obj: any, urExt: string): Observable<any> {
//   return this.http.put(this.url + '/' + urExt + obj.id + '/', obj, this.httpTokenOptions).pipe(
//     tap(_ => this.log(`updated obj id=${obj.id}`))
//     // , catchError(this.handleError<any>('updateObject' + obj.id))
//   );
// }

// patchAnyObject(urExt: string, id: number, patch: any): Observable<any> {
//   return this.http.patch(this.url + '/' + urExt + id + '/', patch,
//     this.httpTokenOptions).pipe();
// }

//#endregion  ### BACKEND_ACCESS_FROM_ANGULAR ###
