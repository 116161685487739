import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//  import { useNavigate } from "react-router-dom";
//  const navigate = useNavigate();
//  navigate("/LoggedOut");

// import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import apiService from "../services/api.service";

function Ankiety() {
  // const Ankiety = (props) => {
  const [data, setData] = useState(null);
  const [mesDat, setMmesDat] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // console.log("xxxxxxXXXXxxxxxx");
    apiService
      .getListData("/router/QuestionnaireViewSet/")
      .then((response) => {
        return response.data;
      })
      .then(
        (data) => {
          if (data) {
            setData(data);
            setMmesDat(JSON.stringify(data));
          }
        },
        (error) => {
          // alert(error.toString())
          if (error.response && error.response.status === 401) {
            navigate("/LoggedOut");
          }
          if (error.message === 'Network Error') {
            navigate("/LoggedOut");
          }
          setErrorMessage(JSON.stringify(error));
        }
      );
  }, []);
  // }, [navigate]); ?????
  // React Hook useEffect has a missing dependency: 'navigate'.
  //  Either include it or remove the dependency array

  function deleteAnkiet(id) {
    // alert(id);
    apiService.deleteSingleElement(
      "/router/QuestionnaireViewSet/" + id + "/"
    ).then(
      (data) => {
        // alert(JSON.stringify(data));

        // setLoading(false);
        // navigate("/home");
        window.location.reload();
      },
      (error) => {
        alert(JSON.stringify(error));
        // const resMessage =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  };

  return (
    <div>
      <div className="Xborder">
        {errorMessage ? (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          </div>
        ) : null}
      </div>

      

      {/* <div className="Xborder float-end mb-4" >
      <a href="AnkietaNowa/">Stwórz Ankietę</a>
      </div> */}
      {data
              ? 
              
      <div className="Xborder">
      <div className="d-grid gap-2 mb-3">
        <Button variant="success" size="lg" href="AnkietaNowa/">
          Stwórz nową ankietę.....
        </Button>
      </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nazwa Ankiety</th>
              <th>Data Utworzenia</th>
              <th>Ilość Pytań</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data
              ? data.map((el) => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td>{el.name}</td>
                    <td>{el.created}</td>
                    {/* <td>{el.created.format("DD-MM-YYYY hh:mm:ss a")}</td> */}
                    <td>{el.questions.length}</td>
                    <td>
                      {/* <a href={"ankiety/" + el.id + ""}>edit</a> */}
                      <a href={"AnkietaEdit/" + el.id + ""}>edit</a>
                      
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          deleteAnkiet(el.id);
                        }}
                      >
                        del
                      </button>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div> : null}

      <br></br>
      <br></br>
      <div className="Xborder">
        {mesDat ? (
          <div className="form-group">
            <div className="alert alert-success" role="alert">
              {mesDat}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Ankiety;

// if (true) {
//   // setErrorMessage(JSON.stringify(error));
//   // setErrorMessage(JSON.stringify(error + '\n\n\n' + error.response));
//   // setErrorMessage(JSON.stringify(error.response));
//   // setErrorMessage(JSON.stringify(error.response.status));
//   // setErrorMessage(JSON.stringify(error.response.data.code ));
//   // setErrorMessage(JSON.stringify(error.response.data.messages));
//   // setErrorMessage(JSON.stringify(error.message));
//   // setErrorMessage(error);
//   // setErrorMessage(error.message);
//   // setErrorMessage(JSON.stringify(error.response.messages));
//   // setErrorMessage(error.response.toString());
// } else {
//   setErrorMessage(JSON.stringify(error));
//   setErrorMessage(error);
//   setErrorMessage(error);
//   // alert(error.status)
//   // alert(error.message)
//   // alert(error.response)
//   // alert(error.response.data)
//   // alert(error.response.data.message);
// }
