import { useState, useEffect } from "react";
// import React, { useState, useEffect } from "react";
// import * as React from "react";
// import Form from "react-bootstrap/Form";
// import FormGroup from "react-bootstrap/Form";
// import FormControlLabel from "react-bootstrap/Form";
// import Switch from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import apiServices/api.service";

function Settings() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    console.log("useEffect  [USER][]: ");
    
  }, []);

  const getData = () => {
    // alert('eureka');
    setMessage('yes');
  };

  return (
    <div className="content">
      <h1>Welcome... Settings</h1>
      <br></br>
      <br></br>

      <a href="http://127.0.0.1:8000/create_all_needed/">http://127.0.0.1:8000/create_all_needed/</a>
      <br></br>

      {/* https://github.com/18F/docker-compose-django-react.git */}
      <a href="http://anapi.ct8.pl">"http://anapi.ct8.pl"</a>
      <br></br>
      <a href="https://github.com/18F/docker-compose-django-react.git">https://github.com/18F/docker-compose-django-react.git</a>
      <br></br>

      <br></br>
      <br></br>
      <br></br>
      
      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
      <br></br>

      <div>
        <Button
          variant="warning"
          onClick={() => {
            getData();

          }}
        >
          Get Data
        </Button>
      </div>
      <br></br>
      {/* {myMyUser && <p className="text-wrap">user: {myMyUser}</p>} */}

   

    </div>
  );
}

export default Settings;

