// import useFetchData from "../services/useFetchData";

import { useState, useEffect } from "react";
import apiService from "../services/api.service";
// import authService from "../services/auth.service";
// import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// import authService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
//  const navigate = useNavigate();
//  navigate("/LoggedOut");

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

function AnkietaNowa() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const [ankietaName, setAnkietaName] = useState(null);

  useEffect(() => {
    // const newAnkietObject = { name: ankietaName };
  }, []);

  const newAnkiet = (e) => {
    e.preventDefault();

    // alert('1aaaa. newAnkiet: ' + newAnkiet);
    const newAnkietObject = { name: ankietaName };
    apiService
      .addSingleElement("/router/QuestionnaireViewSet/", newAnkietObject)
      .then(
        (data) => {
          // alert(JSON.stringify(data.id));
          alert(JSON.stringify(data.data.id));
          const id = data.data.id;
          // setLoading(false);
          navigate("/AnkietaEdit/" + id +'/');
          // window.location.reload();
        },
        (error) => {
          alert(JSON.stringify(error));
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          // setLoading(false);
          setMessage(resMessage);
        }
      );
  };

  return (
    <div>
      <h1>AnkietaNowa...</h1>
      <br></br>

      <Form onSubmit={newAnkiet} className=" ">
        <Form.Group className="mb-3" controlId="">
          <Form.Label>Nazwa Ankiety</Form.Label>
          <Form.Control
            type="text"
            placeholder="wprowadz nazwe"
            value={ankietaName}
            onChange={(e) => setAnkietaName(e.target.value)}
            validations={[required]}
          />
        </Form.Group>
   
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}

        <div className="float-end">
          <Button variant="warning" type="submit" disabled={loading}>
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}{" "}
            &nbsp;
            <span>ok</span>
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AnkietaNowa;
