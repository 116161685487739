import logo from './logo.svg';
import './App.css';

import MenuAndRouting from "./components/MenuAndRouting";

function App() {
  return (
    <div className="App">
        <MenuAndRouting />
    </div>
  );
}

export default App;
