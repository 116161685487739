// import useFetchData from "../services/useFetchData";

import { useState, useEffect } from "react";

import apiService from "../services/api.service";
// import authService from "../services/auth.service";
import { useParams } from "react-router-dom";

import Button from "react-bootstrap/Button";
 import { useNavigate } from "react-router-dom";
//  const navigate = useNavigate();

// const ComponentReact  = (props) =>  vs function ComponentReact() 
function AnkietaSolo() {
  // const { data } = useFetchData("http://127.0.0.1:8000/router/Questionnaire/"); 
  // const [data, setData] = useState(null);
  // const [ankieta, setAnkieta] = useState(null);
  const [resp, setResp] = useState(null);
  const [redat, setRedat] = useState(null);
  const [reerror, setReerror] = useState(null);
  // const [id, setId] = useState(useParams());
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("useEffect  [USER][]: ");
    apiService.getSingleElement('/router/QuestionnaireViewSet/', id)
    .then((response) => {
      setResp(JSON.stringify(response));
      // alert('response\n\n\n' + JSON.stringify(response));
      // alert('id ' + id);
      // if (response.data.access) {
      //   localStorage.setItem("user", JSON.stringify(response.data));
      // }
      // // alert('aaaaaaaaa');
      setRedat(JSON.stringify(response.data));
      return response.data;
      // return "ccccc"
    })

    .then(
      (data) => {
        // setRedat(JSON.stringify(data));
        // alert('data: \n\n\n' + JSON.stringify(data));
        // setLoading(false);
        // // navigate("/home");
        // window.location.reload();
        
      },
      (error) => {
        setReerror(JSON.stringify(error));
        if (error.response && error.response.status === 401) {
          navigate("/LoggedOut");
        }
        // alert('error: \n\n\n' + JSON.stringify(error));

        // const resMessage =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );


  }, [id]);

  return (
    <div>
     <h1>Ankieta... Ankieta Ankieta </h1>
     <br></br>
     <Button
        variant="danger"
        onClick={() => {
        }}
      >
        Log Out
      </Button>
     <br></br>
     <span>
       {/* {ankieta} */}
     </span>
     <br></br>
     <span>
       {id}
     </span>
     <br></br>
     {resp && (
          <div className="form-group">
            <div className="alert alert-warning" role="alert">
              {resp}
            </div>
          </div>
        )}
     <br></br>
     {redat && (
          <div className="form-group">
            <div className="alert alert-success" role="alert">
              {redat}
            </div>
          </div>
        )}
     <br></br>
     {reerror && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {reerror}
            </div>
          </div>
        )}

        
     {/* {data && (
            data.map((user) =>(
                <div className="text-whiteX" key={user.id}>
                    <h1> {user.login} </h1>
                    <h5> {user.login} </h5>
                    <p> { user.login } </p>
                    <p> { user.type } </p>
                </div>
            ))
          )} */}

{/*      
      {true &&
        data.map((recObject) => (
          <div className="text-whiteX border" key={recObject.id}>
            <h1> {user.login} </h1>
            <h5> {recObject.created} </h5>
            <h5> {recObject.modified} </h5>
            <h5>id.  {recObject.id} </h5>
            <h5> {recObject.name} </h5>
            <h5> {recObject.owner_ID} </h5> */}
            
            {/* <h5> {recObject.questions(0).content} </h5>
            <h5> {recObject.questions.created} </h5>
            <h5> {recObject.questions.type} </h5> */}




            {/* <h5> {recObject.created} </h5> */}
            {/* <h5> {formatDate(recObject.recObject)} </h5> */}
            {/* <h5>
              {new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(recObject.created)}
            </h5> */}
            {/* <h5> {recObject} </h5>
                    <h5> {recObject} </h5>
                    <h5> {recObject} </h5>
                    <h5> {recObject} </h5> */}
            {/* <p> { user.login } </p> */}
            {/* <p> { user.type } </p> */}

          {/* </div>
        ))} */}


    </div>
  );
}

export default AnkietaSolo;
