import { useState, useEffect } from "react";

function SingleState() {
// const [name, setName] = useState("Ihechikara " + mycounter);
const [name, setName] = useState("");

const [count, setCount] = useState(0);
const [mycounter, setMycounter] = useState(0);

useEffect(() => {
  console.log(`You have clicked the button ${count} times`);
});

const changeName = () => {
  setName("Chikara - " + mycounter);
  // setMycounter(1 + mycounter);
  setMycounter(mycounter + 1);
};

return (
  <div>
    {/* <form>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Your Name"
      />
      <p>{name}</p>
    </form> */}
  
    {/* <br></br> */}

    <div>
    <p>mycounter is {count}</p>
      <button onClick={() => setCount(count + 1)}>Click me</button>
    </div>

    {/* <div>
      <p>My name is {name}</p>
      <p>mycounter is {mycounter}</p>
      <button onClick={changeName}> Click me </button>
    </div> */}

  </div>
);

// return (
//   <div>
//     <p>My name is {name}</p>
//     <p>mycounter is {mycounter}</p>
//     <button onClick={changeName}> Click me </button>
//   </div>
// );
}

export default SingleState;