
import { useEffect } from "react";
import authService from "../services/auth.service";

export default function LoggedOut() {

  useEffect(() => {
    authService.logOut();
  }, []);

  return (
    <div className="content">
      <h1>You are LoggedOut</h1>
      <h1>Login please....</h1>
    </div>
  );
}
