import useFetchData from '../services/useFetchData'
 
function Users() {
    const { data } = useFetchData("https://api.github.com/users");

  return (
      <div>
          {data && (
            data.map((user) =>(
                <div className="text-whiteX" key={user.id}>
                    {/* <h1> {user.login} </h1> */}
                    <h5> {user.login} </h5>
                    {/* <p> { user.login } </p> */}
                    <p> { user.type } </p>
                </div>
            ))
          )}
      </div>
  )
}

export default Users;
