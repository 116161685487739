// import { Link } from "react-router-dom";

import { useState } from "react";


function Label() {
  const [name, setName] = useState("");

  return (
    <div className="w-50 center border">
      <form>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Your Name"
        />
        <p>{name}</p>
      </form>
    </div>
  );
}
// className class
export default Label;
